/* *{
    transition: all 0.3s ease;
} */

    #menu-root{
        --active:#63cfc9;
        --inactive:#cccccc;
        --low-active:#63cfc999;
        --med-active:#63cfc955;
        --background:#f5f5f5;
    }
   .dark #menu-root{
        --active:#63cfc9;
        --inactive:#555555;
        --low-active:#63cfc999;
        --med-active:#63cfc955;
        --background:#1f1d2b;
        --text:#f5f5f5;
    }
    .dark #menu-root svg{
        fill:var(--text);
    }

/* @media screen and (min-width: 1200px) { */
    #menu-root{
        padding-left: 20px !important;
        display: grid;
        grid-template-columns:minmax(auto,70px) auto;
        position: relative;
        height: 100%;
        background-color: var(--background);
        color:var(--text)
    }
    #menu-rail{
        z-index: 301;
        border-left: solid 1px var(--inactive);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #menu-rail svg{ margin:auto}
    
    #menu-icons{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    #menu-root .is-selected  svg{
        color: var(--active) !important;
        fill: var(--active) !important;
    }
    
    #sub-routes{
        background-color: var(--background);
        --menu-width: 200px;
        --icons-container-width: 50px;
        z-index: 300;
        display: flex;
        flex-direction: column;
        gap: var(--sm);
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        width: var(--menu-width);
        padding: var(--xl);
        box-shadow: 0px 0px 10px 0px var(--med-active);
    }
    
    
    [is="hoverd"]{
        right: var(--icons-container-width) !important;
        width: 200px!important;
    } 
    [is="hidden"],[is="empty"] {
        right: calc(var(--menu-width)*-1) !important;
    }
    [is="active"] {
        position: relative !important;
        right: 0 !important;
    }
    
    #toggle-menu-arrow {
        top: 20px ;
        left: 10px;
        z-index: 1;
        /* transition: all 0.3s ease; */
        position: absolute;
        cursor: pointer;
        margin: auto;
        background-color: var(--inactive);
        border-radius: 50%;
        padding: 2px;
        fill:  var(--active);
        z-index: 302;
        box-shadow: 0 0 10px var(--active);
    }
    
    [is="toggle-active"]#toggle-menu-arrow {
        rotate: 180deg;
        top: 20px !important;
        fill:  var(--inactive);
        box-shadow: 0 0 10px var(--inactive);
    }
    
    #toggle-menu-arrow:hover {
        box-shadow: 0 0 10px var(--active);
        fill: var(--active);
        transition: all 0.2s ease;
    }
    
    [is="empty"]{
        opacity: 0 !important;
    }
    
    .menu-label{
        background-color: var(--prince);
    }
    .menu-title{
        color: var(--shark);
    }
    
    .menu-buttons-container{
        background-color: var(--background);
        padding: 10px;
        display: grid;
        grid-template-rows: auto 1fr auto;
        gap: var(--3xl);
    }
    
    #menu-resizer {display: none;}
    [is="active"] #menu-resizer{
        display: block !important;
        /* background-color:#0001; */
        position: absolute;
        width: 5px;
        top: 0;
        bottom: 0;
        left: -3px;
        cursor: col-resize;
    }
    
    .sub-route-label{
        color: var(--shark);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    
/* } */

.link{
    text-decoration: none;
}
#root{
    display: grid;
    grid-template-columns: 55px 1fr ;
    height: 100vh;
}

@media screen and (max-width : 1200px){
    #menu-root{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row: 2;
    }
    #menu-rail{
        z-index: 301;
        border: none;
    }
    #menu-icons{
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
    
    #sub-routes{
        background-color: var(--text);
        --menu-width: 200px;
        --icons-container-width: 50px;
        z-index: 303;
        display: flex;
        flex-direction: column;
        gap: var(--sm);
        position: fixed;
        bottom: 0;
        top: 0;
        right: 0;
        width: var(--menu-width);
        padding: 0px;
    }
    
    [is="hidden"],[is="empty"] {
        right: calc(var(--menu-width)*-1) !important;
        background-color: #0003;
    }
    [is="active"] {
        right: 0 !important;
        left: 0 !important;
    }

    #toggle-menu-arrow {
        position: fixed;
        top: 20px !important;
        right: 20px !important;
        z-index: 305;
    }
    #menu-resizer {display: none;}

    #top-bar{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-shadow:  0px 2px 4px var(--low-active);
    }

    #root {
        grid-template-columns: unset;
        grid-template-rows:  1fr auto;
    }

}