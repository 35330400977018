.toggle-theme-button{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 333;
    padding: 5px;
    margin: -3px;
    background-color: var(--throne);
    border-radius: 0 0 10px 0 ;
    box-shadow: var(--shadow-lg);
    cursor: pointer;
  }
  
.light{
    background-color:  #f6f6f6;
}
.dark{
    background-color:  #1f1d2b;
}

.dark .background-opacity{
    background-color:  #1f1d2b33;
    backdrop-filter: blur(1.5px);
}
.light .background-opacity{
    background-color:  #f6f6f633;
    backdrop-filter: blur(1.5px);
}

.dark .background{
    background-color:  #1f1d2b;
}
.light .background{
    background-color:  #f6f6f6;
}

.dark .card-1{
    background-color:  #353848;
}
.light .card-1{
    background-color:  #ffffff;
}

.dark .card-2{
    background-color:  #2d303e;
}
.light .card-2{
    background-color:  #fbfbfb;
}

.dark .card-3{
    background-color:  #393c4a;
}
.light .card-3{
    background-color:  #ffffff;
}
.dark .card-4{
    background-color:  #ffffff;
}
.light .card-4{
    background-color:  #1f1d2b;
}

.dark .text-1{
    color: #ffffff;
 
}
.light .text-1{
    color:  #1f1d2b;
}

.dark .text-2{
    color: #767982;
}
.light .text-2{
    color:  #c4c4c7;
}

.dark .text-3{
    color: #bbbcc0;
}
.light .text-3{
    color:  #c4c4c7;
}

.dark .text-3{
    color: #6c6f78;
}
.light .text-3{
    color:  #c4c4c7;
}



.text-orange-1{
    color: #ffa44e;
}
.text-red-1{
    color: #df3743;
}
.text-green-1{
    color: #56e181;
}
.text-purple-1{
    color: #766cfa;
}
.text-cyan-1{
    color: #63cfc9;
}
.text-blue-1{
    color: #3fb4de;
}


.bg-orange-1{
    background-color: #ffa44e;
}
.bg-red-1{
    background-color: #df3743;
}
.bg-green-1{
    background-color: #56e181;
}
.bg-purple-1{
    background-color: #766cfa;
}
.bg-cyan-1{
    background-color: #63cfc9;
}
.bg-blue-1{
    background-color: #3fb4de;
}


.dark .tr-1{
background-color:#1f1d2b ;
}
.dark .tr-2{
background-color:#3c3f4c ;
}

.light .tr-1{
background-color:#f6f6f6 ;
}
.light .tr-2{
background-color:#fbfbfb ;
}

.table-blur td{
    opacity: 0.3;
    filter: blur(0.8px);
}

.tr-active td{
    opacity:1;
    filter: unset;
    font-size: 22px;
}

.td-dangers{
    background-color:#766cfa ;
    color: #fff;
}
.td-good{
    background-color:#63cfc9 ;
    color: #fff;
}
.td-warning{
    background-color:#df3743 ;
    color: #fff;
}

.shadow {
    box-shadow: 0 0 6px #00000033 ;
}

.drag{
    /* opacity: 0.3; */
    filter: blur(1px);
}
