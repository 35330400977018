.dark {background-color: #1f1d2b;}
.background {background-color: #f5f5f5;}
.dark .background {background-color: #1f1d2b;}


.item-icon-bg {fill: #efeff0;}
.dark .item-icon-bg {fill: #5c5e69;}


.item-defult-icon-bg {background-color: #f4f4f5;}
.dark .item-defult-icon-bg {background-color:  #3c3f4c;}

td {background-color: #f5f5f5;}
.dark td {background-color: #1f1d2b;}
td{color: #1f1d2b; padding: 10px;}
.dark td{color: #f5f5f5;}



.carbonic-card{background-color: #FFFFFF;} 
.dark .carbonic-card{background-color: #2d303e;} 

.carbonic-card-1{background-color: #FBFBFB;} 
.dark .carbonic-card-1{background-color: #353848;} 

.carbonic-card-2{background-color: #FFFFFF;} 
.dark .carbonic-card-2{background-color: #393c4a;} 

.carbonic-card-3{background-color: #FBFBFB;} 
.dark .carbonic-card-3{background-color: #292a39;} 

.carbonic-card-4{background-color: #F0F0F0;} 
.dark .carbonic-card-4{background-color: #393c4a;} 

.carbonic-card-5{background-color: #f6f6f6;} 
.dark .carbonic-card-5{background-color: #3b3e4c;} 

.carbonic-card-6{background-color: #eaeaeb;} 
.dark .carbonic-card-6{background-color: #eaeaeb;} 

.carbonic-card-7{background-color: #fbfbfb;} 
.dark .carbonic-card-7{background-color: #3c3f4c;} 

.carbonic-card-8{background-color: #f5f5f5;} 
.dark .carbonic-card-8{background-color: #22212e;} 

.blur-card{background-color: #f5f5f588;} 
.dark .blur-card{background-color: #22212e88;} 

.blur-card-1{background-color: #63cfc955;} 
.dark .blur-card-1{background-color: #63cfc955;} 

.blur-card-2{background-color: #dd364355;} 
.dark .blur-card-2{background-color: #dd364355;} 

.white-card{background-color: #FFFFFF;}

 
.search-bg {background-color: #ffffff;}
.dark .search-bg {background-color: #3c3f4c;}

.account-check-bg {background-color: #ffffff;}
.dark .account-check-bg {background-color: #3c3f4c;}


.carbonic {color: #1f1d2b;}
.dark .carbonic {color: #ffffff;}


.carbonic-1 {color: #c3c3c6;}
.dark .carbonic-1 {color: #6c6e78;}


.carbonic-2 {color: #565077;}
.dark .carbonic-2 {color: #a4a8b4;}


.carbonic-3 {color: #c4c4c7;}
.dark .carbonic-3 {color: #6c6e78;}


.carbonic-4 {color: #bebec1;}
.dark .carbonic-4 {color: #767982;}

.carbonic-5 {color: #5a577a;}
.dark .carbonic-5 {color: #c2c2c2;}

.carbonic-5 {color: #5a577a;}
.dark .carbonic-5 {color: #5a577a;}


.check-bg {background-color: #f3f3f1;}
.dark .check-bg {background-color: #4c4e5a;}

.compo-bg {background:rgba(196, 196, 199,0.85);}
.dark .compo-bg {background:rgba(31, 29, 43,0.85);}


.bg-logout {background-color: #f4e2e3;}

.dark .bg-logout {background-color: #36232f;}


.menu-label {background-color: #fcfcfc;}
.dark .menu-label {background-color: #2d2c39;}

.red-card {background-color: #dd3643;}
.red-card-1 {background-color: #df3743;}
.red-card-2 {background-color: #ec4245;}

.orange-card {background-color: #f48114;}
.orange-card-1 {background-color: #ffa44e;}
.orange-card-2 {background-color: #f9c089;}

.cyan-card {background-color: #63cfc9;}
.blue-card {background-color: #3fb4de;}
.pruble-card {background-color: #766cfa;}
.green-card {background-color: #56e181;}
.white-card {background-color: #ffffff;}
.check-card {background-color: #e9e9eb;}

.dark .check-bg {background-color: #494c5a;}
.red-border {border-color: #dd3643;}

.cyan-hover :hover{background-color: #63cfc9; color:#fff; }

.green-card {background-color: #56e181;}


.red-text {color: #dd3643;}
.red-text-1 {color: #df3743;}
.red-text-2 {color: #ec4245;}

.orange-text {color: #f48114;}
.orange-text-2 {color: #ffa44e;}
.orange-text-3 {color: #f9c089;}
.dark .orange-text-3 {color: #905929;}
.dark .orange-text-4 {color: #ffb938;}

.cyan-text {color: #63cfc9;}
.green-text {color: #56e181;}
.white-text {color: #fff;}
.blue-text {color: #3fb4de;}
.pruble-text {color: #766cfa;}


.dark .red-text-1 {color: #ffffff;}

.orange-fill {fill: #f48114;}
.orange-fill-2 {fill: #ffa44e;}
.orange-fill-3 {fill: #f9c089;}
.dark .fill-3 {fill: #905929;}
.dark .fill-4 {fill: #ffb938;}
.cyan-fill {fill: #63cfc9;}
.green-fill {fill: #56e181;}
.red-fill {fill: #dd3643;}
.white-fill {fill: #fff;}
.red-fill-1 {fill: #ec4245;}


/* .green-text {color: #1bd69a;} */
.menu-phone {color: #6b6d78;}
/* MenuPhone */
.dark .menu-phone {color: #c4c4c7;}

/* -///////////////////- */
/* -///////////////////- */
/* tincture */
.tint {fill: #c4c4c7;}

.dark .tint {fill: #6c6f78;}


.tint-stroke {stroke: #c4c4c7;}
.dark .tint-stroke {stroke: #6c6f78;}


.tint-1 {fill: #c3c3c6;}
.dark .tint-1 {fill: #72747f;}


.tint-2 {fill: #b8b7bc;}
.dark .tint-2 {fill: #72747f;}


.tint-item {fill: #d5d6d9;}
.dark .tint-item {fill: #6d6f78;}


.tint-menu {fill: #b8b7bc;}
.dark .tint-menu {fill: #96969c;}


.tint-tab {fill: #bebec1;}
.dark .tint-tab {fill: #62606b;}

.tint-tab {fill: #dd3643;}

.tint-main-tab {fill: #fff;}
.dark .tint-main-tab {fill: #8b8a91;}

.active .tint-main-tab {fill: #fff;}

.tint-squer {border-color:  #d9d9da;}
.dark .tint-squer {border-color: #767982;} 



/* Accounts */
.accounts-business-svg {fill: #efeff0;}
.dark .accounts-business-svg {fill: #5c5e69;}


/*  */

/* Accounts */

/* -///////////////////- */
/* -///////////////////- */

.border-left {border-left: solid 1px #ebebeb;}

.dark .border-left {border-left: solid 1px #1f1d2b;}


.border-top {border-top: solid 1px #ebebeb;}

.dark .border-top {border-top: solid 1px #1f1d2b;}


.menu-profile-border {border: solid 2px #dcdcde;}
/* MenuImageBorder */
.dark .menu-profile-border {border: solid 2px #3a3a46;}


/* -///////////////////- */
/* -///////////////////- */

.backButton {background-color: #c6c6ca;padding: 6px 10px;}

.dark .backButton {background-color: #8f8e95;padding: 6px 10px;}


/* -///////////////////- */

/*  *** *** *** ***  */
/*  *** *** *** ***  */
.un-active-mode {background: linear-gradient(90deg, #bebec1, #bebec1);}
.dark .un-active-mode {background: linear-gradient(90deg, #65636d, #65636d);}


.acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}
.dark .acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}

.acitve-mode-shadow {box-shadow: 0px 0px 25px #e7353c;/* rgba(231, 53, 60, 1); */}

.mode-svg {fill: #fff0;}

.acitve-mode .mode-svg {fill: #fff;}

.item-quantity {left: -10px;fill: #fff;display: flex;padding: 0 4px;position: absolute;border-radius: 25px;flex-direction: row;align-items: center;background-color: #f48114;border: solid 2px #d17117;
}

/*  *** *** *** ***  */
 
.carbonic-card-svg {fill: #ffffff;}
.dark .carbonic-card-svg {fill: #2d303e;}


.invoice-top {background-color: #f5f5f5;}
.dark .invoice-top {background-color: #3d404e;}


.invoice-shape {background-color: #e6e6e6;}

.dark .invoice-shape {background-color: #393c4a;}

.invoice-shape-svg {fill: #e6e6e6;}

.dark .invoice-shape-svg {fill: #393c4a;}


.invoice-border-svg {fill: #ecebe8;}

.dark .invoice-border-svg {fill: #494c5a;}


.invoice-number-svg {fill: #c4c4c7;}
.dark .invoice-number-svg {fill: #4d505c;}


.invoice-bg-date {background-color: #fbfbfb;border-top: solid 1px #ebebeb;}
.dark .invoice-bg-date {background-color: #292a39;border-top: solid 1px #393d4d;}


.invoice-bg-date-2 {background-color: #fbfbfb;border: solid 1px #ededee;}
.dark .invoice-bg-date-2 {background-color: #383b48;border: solid 1px #4c4f5b;}


.invoice-quantity {background-color: #c4c4c7;border: solid 2px #b9b9bb;fill: #fff;}

.dark .invoice-quantity {background-color: #484a57;border: solid 2px #3f414e;fill: #767982;}


.invoice-bg-header {background-color: #faf9f7;border-bottom: solid 1px #e7e6de;}
.dark .invoice-bg-header {background-color: #393c4a;border-bottom: solid 1px #4c4f5b;}


.invoice-bg-items {background-color: #ffffff;}
.dark .invoice-bg-items {background-color: #313442;}


.tint-price-svg {fill: #c4c4c7;}

.dark .tint-price-svg {fill: #6c6e78;}


/* Invoices */

.tab-container-bg {background-color: #f5f5f550;}
.dark .tab-container-bg {background-color: #1f1d2b50;}


/* Svgs */
.text-input{border-radius : 10px;fill:#c4c4c7 ;background-color: #ffffff;}
.dark .text-input{fill:#767982 ;background-color: #3c3f4c;}

.number-input{border-radius : 10px;fill:#c4c4c7 ;background-color: #ffffff;}
.dark .number-input{fill:#767982 ;background-color: #3c3f4c;}


.text-input input{background-color: #ffffff;}
.dark .text-input input{background-color: #3c3f4c;}


.dark .icon-card svg{fill: #767982;stroke: #494c5a;}

.light .icon-card svg{fill: #ecebe8;stroke: #bebec1;}

.select-icon{fill: #1f1d2b;}
.dark .select-icon{fill: #9e9fa6;}

.border-none{border: none;}

.svg-icon-with-bg{padding:10px;width: 40px;fill: #bebec1;border-radius: 10px;background-color: #FFFFFF;box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);}
.dark .svg-icon-with-bg{background-color: #2d303e;}


.svg-icon{fill: #bebec1; height: 36px;}
.svg-icon .border{fill: #ecebe8;}
.container-checked .svg-icon{fill: #f1bac4;}
.container-checked .svg-icon .border{fill: #e87f90;}
.container-checked .carbonic-3{color: #f0b9c5;}




.not-fount .svg-icon{fill: #DCDCDD;background-color:#f0f1f1 ;height: 36px;border-radius: 10px;}
.not-fount .svg-icon .border{fill: #f0f1f1;}

.not-fount p{color: #D9DDDD;}

/* Svgs */

.read-only-bg{border-radius : 14px;padding: 10px;fill:#c4c4c7 ;background-color: #e8e8e9;border : solid 2px #efeff0;}
.read-only-bg input{background-color: #e8e8e9;}

