@tailwind base;
@tailwind components;
@tailwind utilities;

.rapper{
    padding: 10px;
}
.trans {
    transition: all 200ms ease-in-out;
}
/* .trans-none {
    transition:  all 2ms ;
} */

*:focus,
*:active {
    outline: none !important;
    border: 1;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.scroller{
    overflow: auto;
}

.scroller::-webkit-scrollbar {
    height: 3px;
    width: 8px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    /* background-image: linear-gradient(90deg, #fa7f02, #d90429); */
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.non-trans {
    transition: none;
}

.font-cairo {
    font-family: "Cairo", sans-serif;
}

.fontBold {
    font-family: "'BoutrosMBCDinkum Medium'";
}
p {
    font-family: "'BoutrosMBCDinkum Medium'";
}
span {
    font-family: "'BoutrosMBCDinkum Medium'";
}
input {
    font-family: "'BoutrosMBCDinkum Medium'";
}
textarea {
    font-family: "'BoutrosMBCDinkum Medium'";
}
button {
    font-family: "'BoutrosMBCDinkum Medium'";
}

.font-bolder {
    font-weight: bolder;
}
/* .font-Boutros-Medium {
    font-family: "'BoutrosMBCDinkum Medium'";
} */
/* .font-Boutros-Bold {
    font-family: "'GE Dinkum Medium'";
} */

.bm-menu {
    background: #fafaf9;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0) !important;
}

.text-primary {
    color: #020204;
}

.bg-primary {
    color: #ecf0f3;
}

.bg-primary-dark {
    color: #04001c;
}

.neu-card {
    border-radius: 10px;
    background: linear-gradient(145deg, #d4d8db, #fdffff);
    box-shadow: 8px 8px 8px #c9cccf, 8px 8px 8px #ffffff;
}
.neu-card:hover {
    background: #ecf0f3;
    box-shadow: 10px 10px 20px #c9cccf, 10px 10px 20px #ffffff;
}

.overflow-y-hidden-important {
    overflow-y: hidden !important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bounce-in {
    animation: bounce-in-frames 300ms;
}

.bounce-out {
    animation: bounce-out-frames 150ms;
    display: none;
}

@keyframes bounce-in-frames {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounce-out-frames {
    20% {
        transform: translate3d(0, 10px, 0) scaleY(0.985);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    to {
        opacity: 0;
        display: none;
    }
}

@page {
    size: 8cm auto;
    width: 8cm;
    height: 100%;
    margin: 0;
    margin-left: 5px !important;
}

.orange {
    background-color: #e85d04dd;
}
.orangeO {
    background-color: #e85d0499;
}

.myGray {
    background-color: #6b7280;
}

.myGrayO {
    background-color: #6b7280cc;
}

.myBlue {
    background-color: #60a5fa;
}

.myBlueO {
    background-color: #60a5facc;
}

.myGreenO {
    background-color: #6cb073cc;
}

.myGreen {
    background-color: #6cb073;
}

/* .orange {
    background-color: #6b7280cc;
} 

 .myGrayO {
    background-color: #92c904aa;
}

.myGray {
    background-color: #92c904;
} 

 .myGreen {
    background-color: #17d4c2cc;
} */
/* const colors = ["#17d4c2", "#f7b645", "#92c904", "#d6409f"]; */

/* .fadeOutPakage {
    animation: fadeOutFrames 0.3s ease-out;
    flex: 0;
}

@keyframes fadeOutFrames {
    from {
        flex: 1;
    }
    to {
        flex: 0;
    }
} */

.fadeInPakage {
    animation: fadeInFrames 0.2s ease-out;
    flex: 1;
}

@keyframes fadeInFrames {
    from {
        flex: 0.5;
    }
    to {
        flex: 1;
    }
}

.selected-price-container {
    border-radius: 20px;
    margin: 5px 0px;
    padding: 3px;
    font-size: 12px;
}
.selected-price-container span {
    padding: 3px;
    color: #707070;
}

.selected-price-container .active {
    border-radius: 15px;
    background-color: #40acdc;
    color: #fff;
}

.selected-price-container-1 .price-two {
    font-size: 12px;
}

table {
    width: 100%;
}

.print-container {
}
.print-border {
    border: 1px solid #fff;
}

.print-container .print-border {
    border-color: #cccccc;
}


.main-container{
    position: fixed;
    inset: 0;
    padding: 10px;
}
.popup{
    width: 100%;

}
@media screen and (min-width: 1280px) {
     .main-container {
      position: fixed;
      inset: 0;
      padding: 2% 10%;
}
.popup{
    padding-right:10% ;
    width: 720px;
}
}